// URL CONFIG
//export const apiUrl = 'https://betaapi.mudhir.app';
export const apiUrl = 'https://api.fastinventory.io';
export const baseUrl = "https://fastinventory.io";
export const totodealApiUrl = "https://api.totodeal.xyz";

// API CONFIG
export const apiPrefix = "api";

// APP CONFIG
export const googleTrackingID = 'UA-xxxxxxxx-x';
export const appName = "FastInventory";
export const appWebsite = "https://example.com/";
export const devName = "Developer Name";
export const devWebsite = "https://example.com/";
export const classPrefix = "app-name";
export const googleMapApiKey = "xxxxxxxxxxxxxxxxxxx";
export const googleMapDefaultLocation = {
  lat: 23.7808875,
  lng: 90.2792371
};
export const googleMapCountryList = ['bd']
export const googleMapDefaultZoom = 14;
export const timezone = 'Asia/Dhaka';
export const fbID = "xxxxxxxxxxxxxxxxxxx";
export const fbPageID = "xxxxxxxxxxxxxxxxxxx";

export const siteTitle = 'FastInventory.io';
export const siteLogo = "/images/logo/logo-1.png";
export const siteTagLine = 'Smart Sales & Inventory Solution';
export const metaSiteTitle = siteTagLine + " | " + siteTitle;
export const metaDesc = "Fast Inventory: Uplift your business, boost profits with smooth inventory and sales management.";
export const metaKeywords = "Lorem, Ipsum, dummy, text, typesetting, industry, standard, printer, galley, scrambled";
