import { useState, useEffect } from 'react';

const useLocalStorage = (initialValue) => {
  const [state, setState] = useState(() => {
    if (typeof window !== 'undefined') {
      const valueInLocalStorage = window.localStorage.getItem('lang');
      if (valueInLocalStorage) {
        return valueInLocalStorage;
      } else {
        return initialValue;
      }
    }
  });

  useEffect(() => {
    window.localStorage.setItem('lang', state);
  }, [state]);

  return [state, setState];
};

export default useLocalStorage;
