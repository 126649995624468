import ShopService from "@/services/api/shop.service";
import { createSlice } from "@reduxjs/toolkit";

export const userInfo = createSlice({
  name: "user information",
  initialState: {
    loading: false,
    profile: null,
    appsInfo: null,
  },
  reducers: {
    initUserData: (state, action) => {
      const data = action.payload;
      //   console.log("initUserData", data, data.due);

      return {
        ...state,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateApps: (state, action) => {
      const data = action.payload;
        // console.log("action.payload", data);
        
        state.appsInfo.push(data);
        
      // const exPinfo = state.products.find((x) => x.id == data?.id);
    },
  },
});

export const {
  initUserData,
  updateApps,
  setLoading,
} = userInfo.actions;

export default userInfo.reducer;
