import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "../public/css/bundle.css";
import "../styles/style.scss";
import "../styles/responsive.scss";

import React from "react";
import Router from "next/router";
import { SessionProvider } from "next-auth/react";
import localFont from "@next/font/local";
import NProgress from "nprogress";
import { Provider as ReduxProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { appWithI18Next } from "ni18n";
import { ni18nConfig } from "../ni18n.config";

import "react-toastify/dist/ReactToastify.css";

import { wrapper } from "../redux/store";
import DefaultLayout from "../components/common/layouts/DefaultLayout";

Router.events.on("routeChangeStart", (url) => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// const pragmatica = localFont({
//   src: "./fonts/Pragmatica-ExtraLight.woff",
//   variable: "--font-pragmatica-extraLight",
// });

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  const { store } = wrapper.useWrappedStore({ initialState: { foo: "bar" } });

  const Layout = Component.Layout || DefaultLayout;
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <>
      <SessionProvider session={session}>
        <ReduxProvider store={store}>
          <CookiesProvider>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <Layout>
                  <Component {...pageProps} />
                </Layout>

                <ToastContainer />
              </Hydrate>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </CookiesProvider>
        </ReduxProvider>
      </SessionProvider>
    </>
  );
};

// export default wrapper.withRedux(appWithI18Next(MyApp, ni18nConfig));
export default appWithI18Next(MyApp, ni18nConfig);
