import { apiPrefix } from '../../config/config';
import { apiEndpoint } from '../apiEndpoint';
import { api, authApi } from '../interceptor/auth.interceptor';
import { apiUrl } from '../../config/config';
class SiteService {
	static async getSiteSettings() {
		return await api.get('/' + apiPrefix + '/siteSetting').then((response) => {
			return response.data.data;
		});
	}

	static async getCmsFeatures() {
		return await api.get(apiUrl + apiEndpoint.csmFeatures.allFeatures).then((response) => {
			return response?.data;
		});
	}

	static async getSingleFeature(params) {
		return await api.get(apiUrl + apiEndpoint.csmFeatures.allFeatures + params.query.slug).then((response) => {
			return response?.data;
		});
	}
}

export default SiteService;
