import ShopService from "@/services/api/shop.service";
import { thousandFormatter } from "@/utils/number";
import { createSlice } from "@reduxjs/toolkit";


export const purchaseSlice = createSlice({
  name: "purchase",
  initialState: {
    loading: false,
    products: [],
    shopInfo: null,
    totalPrice: 0,
    subtotal: 0,
    paidTotal: 0,
    vatTotal: 0,
    delivery_charge: 0,
    payment_method: null,
    bank: null,
    customer: null,
    bankData: [],
  },
  reducers: {
    updateProducts: (state, action) => {
      const data = action.payload;
      //   console.log('action.payload', data);

      const exPinfo = state.products.find((x) => x.id == data?.id);

      if (exPinfo) {
        exPinfo.quantity++;
      } else {
        // console.log("base_price", parseFloat(data.base_price).toFixed(2));
        const itemInfo = {
          id: data?.id,
          product_code: data?.product_code,
          name: data?.name,
          unitPrice: data.base_price || 0,
          quantity: 1,
        };
        state.products.push(itemInfo);
      }
    },
    updateProductPrice: (state, action) => {
      const data = action.payload;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === data.productId) {
          return {
            ...product,
            unitPrice: parseFloat(data.unitPrice).toFixed(2) || 0,
          };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },
    updateVatTotal: (state, action) => {
      const __vat =
        (state.subtotal * parseFloat(action.payload || 0).toFixed(2)) / 100;

      return {
        ...state,
        vatTotal: __vat,
      };
    },
    setVatTotal: (state, action) => {
      const __vat = parseFloat(action.payload || 0).toFixed(2);

      return {
        ...state,
        vatTotal: __vat,
      };
    },
    setProductsMeta: (state, action) => {
      const productId = action.payload.id;
      const productQty = action.payload.qty;
      const productPrice = parseFloat(action.payload.price) || 0;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          return { ...product, quantity: productQty, unitPrice: productPrice };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },

    setProductQty: (state, action) => {
      const productId = action.payload.product?.id;
      const productQty = action.payload.quentity;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          return { ...product, quantity: Number(productQty) };
        }

        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },

    incrementProduct: (state, action) => {
      const productId = action.payload;
      const products = [...state.products];

      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          return { ...product, quantity: product.quantity + 1 };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },
    decrementProduct: (state, action) => {
      const productId = action.payload;

      const updatedProducts = state.products.map((product) => {
        if (product.id === productId && product.quantity > 1) {
          return { ...product, quantity: product.quantity - 1 };
        }
        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };
    },

    removeProduct: (state, action) => {
      const productId = action.payload;

      const updatedProducts = state.products.filter(
        (product) => product.id !== productId
      );

      return {
        ...state,
        products: updatedProducts,
      };
    },
    fetchShopInfo: (state, action) => {
      return {
        ...state,
        shopInfo: action.payload,
      };
    },
    calcPrices: (state, action) => {
      let returningStates = {
        subtotal: 0,
        vatTotal: 0,
        total: 0,
        discount: 0,
      };

      const products = [...state.products];

      let subtotal = 0;
      const vat = parseFloat(state.vatTotal) || 0;

      products.map((val, idx) => {
        subtotal += val.quantity * val.unitPrice;
      });
      returningStates.subtotal = thousandFormatter(subtotal);

      returningStates.total = thousandFormatter(Math.round(subtotal + vat));

      return {
        ...state,
        subtotal: returningStates.subtotal,
        totalPrice: returningStates.total,
      };
    },
    updatePaidTotal: (state, action) => {
      const paidAmount = action.payload;

      return {
        ...state,
        paidTotal: paidAmount,
      };
    },
    updatePaymentMethod: (state, action) => {
      return {
        ...state,
        payment_method: action.payload,
      };
    },
    updatePaymentBank: (state, action) => {
      return {
        ...state,
        bank: action.payload,
      };
    },
    updateCustomer: (state, action) => {
      return {
        ...state,
        customer: action.payload,
      };
    },
    saveSell: (state, action) => {},
    clearSale: (state, action) => {
      return {
        ...state,
        products: [],
        // shopInfo: null,
        totalPrice: 0,
        subtotal: 0,
        paidTotal: 0,
        vatTotal: 0,
        delivery_charge: 0,
        payment_method: null,
        bank: null,
        customer: null,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const {
  updateProducts,
  updateProductPrice,
  setProductsMeta,
  incrementProduct,
  decrementProduct,
  removeProduct,
  fetchShopInfo,
  calcPrices,
  setVatTotal,
  updateVatTotal,
  updatePaidTotal,
  updatePaymentMethod,
  updatePaymentBank,
  saveSell,
  updateCustomer,
  clearSale,
  setLoading,
  setProductQty,
} = purchaseSlice.actions;

export default purchaseSlice.reducer;
