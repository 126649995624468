import axios from 'axios';
import { apiUrl } from '../../config/config';
import { refreshToken, TOKEN_STORE_KEY } from '../../constants/storage';

import Cookies from 'universal-cookie';
import { removeToken, storeToken, retrieveToken } from '@/lib/utils/tokenStore';
import { removeUser } from '@/lib/utils/userStore';
const cookies = new Cookies();
const api = axios.create({
  baseURL: apiUrl,
});

const authApi = axios.create({
  baseURL: apiUrl,
});

authApi.interceptors.request.use(
  async (config) => {
    let accessToken = cookies.get(TOKEN_STORE_KEY);

    if (!accessToken) {
      // if (accessToken) {
      //   const formData = new FormData();
      //   formData.append(refreshToken, accessToken);
      //   const { data } = await axios.post(apiUrl + '/oauth/token', formData);
      //   console.log('incepetor data', data);
      //   if (data) {
      //     setCookie(accessToken, data.accessToken, data.expires_in);
      //     setCookie(refreshToken, data.refreshToken);
      //   }
      //   accessToken = data.accessToken;
      // } else {
      //   window.location = '/login';
      // }
    }

    if (accessToken) config.headers.Authorization = `token ${accessToken}`;
    return config;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err);
  }
);

authApi.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    const originalRequest = error.config
    // console.log(error)
    if (
      error.response?.status === 401 
      // &&
      // originalRequest.url === apiUrl
    ) {
      // removeToken();
      // removeUser();
      // router.push('/login')
      return Promise.reject(error)
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = localStorageService.getRefreshToken()
      return axios
        .post('/oauth/token', {
          refresh_token: refreshToken
        })
        .then(res => {
          if (res.status === 201) {
            storeToken(res.data.accessToken)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + retrieveToken()
            return axios(originalRequest)
          }
        })
    }
    return Promise.reject(error)
  }
)

export { api, authApi };
