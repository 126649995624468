import { apiUrl } from '../../config/config';
import { api, authApi } from '../interceptor/auth.interceptor';
import { apiEndpoint } from '../apiEndpoint';
import * as _qs from 'qs';

class ShopService {
	static async getShopInfo() {
		return await authApi.get(apiUrl + apiEndpoint.shop.shopInfo).then((response) => {
			// console.log(response);
			return response?.data;
		});
	}

	static async createShopInfo(formData) {
		return await authApi.post(apiUrl + apiEndpoint.shop.shopSetting, formData).then((response) => {
			return response?.data;
		});
	}
	static async updateShopInfo(formData) {
		return await authApi.post(apiUrl + apiEndpoint.shop.shopInfo, formData).then((response) => {
			return response?.data;
		});
	}

	static async getPaymentMethods() {
		return await authApi.get(apiUrl + apiEndpoint.payment.paymentMethod).then((response) => {
			// console.log(response);
			return response?.data;
		});
	}

	static async listPaymentMethodsWithQueries(queries) {
		return await authApi.get(apiUrl + apiEndpoint.payment.paymentMethod + '?' + _qs.stringify(queries)).then((response) => {
			// console.log(response);
			return response?.data;
		});
	}

	static async getPaymentMethodBank(id) {
		return await authApi.get(apiUrl + apiEndpoint.payment.paymentMethod + `${id}/get_banks`).then((response) => {
			// console.log(response);
			return response?.data;
		});
	}
}

export default ShopService;
