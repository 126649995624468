export const apiEndpoint = {
  otpSendUrl: "/send_otp",
  otpVerifyUrl: "/verify_user",
  emailVerifyUrl: "/verify_email",
  signupUrl: "/register",
  googleOauth: "/auth/google/",
  googleOauth2: "/google/login/",
  loginUrl: "/login",
  logoutUrl: "/logout",
  changePassword: "/change_password",
  publicRoutes: {
    homepage: "/cms/homepage/",
  },
  csmFeatures: {
    allFeatures: "/cms/features/",
  },

  resetPassword: {
    sendOtp: "/send_otp_registerd_email",
    verifyOtp: "/validate_otp_registerd_email",
    passwordReset: "/change_forgot_password/email",
  },
  user: {
    allUsers: "/user",
  },
  dashboard: {
    root: "/dashboard/",
    dashboardData: "/dashboard/dashboard-data/",
    cashflowData: "/dashboard/cash-flow/",
    transactionCount: "/dashboard/transactioncount/",
  },
  inventory: {
    allCategoryUrl: "/inventory/category/",
    createCategoryUrl: "/inventory/category/",
    allproducts: "/inventory/product/",
    allposproducts: "/inventory/pos-product/",
    warehousePOSProducts: "/inventory/warehouse-product/pos",
    nonSellableProduct: "/inventory/non-saleable-product/",
    decreaseProduct: "/inventory/decrease-product/",
    nonSaleableInvoiceList: "/inventory/nonsaleable-invoice/list",
    allUnits: "/inventory/unit/",
    allStocks: "/inventory/product-stock/",
    productIndexPDF: "/inventory/product-index-pdf",
    wareHouse: "/inventory/warehouse/",
    wareHouseStockTransfer: "/inventory/warehouse/stock/transfer/",
    receivedStockTransfer: "/inventory/stock-transfer/received",
    cancelledStockTransfer: "/inventory/stock-transfer/cancelled",
    wareHouseProductStock: "/inventory/warehouse-product/stock",
    wareHouseProductStockPDF: "/inventory/warehouse-product/stock/pdf",
    warehouseProduct: "/inventory/warehouse/product/list/",
    stockTransferInvoice: "/inventory/stock-transfer/invoice",
  },
  customer: {
    allCustomerUrl: "/expense/customer-list/",
    createCustomerUrl: "/expense/customer-list/",
    CustomerIndexPDF: "/expense/customer-index-pdf",
  },
  stuffUser: {
    allStuffUser: "/staff-users/",
  },
  userRole: {
    allUserRole: "/role/",
    allPermissions: "/permissions/",
    allPermissionResource: "/permission_resource/",
  },
  expense: {
    allExpenseUrl: "/expense/expense/",
    allExpenseCategoryUrl: "/expense/expense-category/",
    createExpenseUrl: "/expense/expense/",
    allAdvanceSell: "/expense/advance-sell/",
    returnSell: "/expense/return-sale/",
    inventoryMovement: "/expense/inventory-movement/",
    getUserSales: "/expense/customer-sales/",
    getUserSalesReturn: "/expense/return-sale/",
    generatePDFUserSales: "/expense/generate-pdf-customer-sales",
    generatePDFSalesQuotationSales: "/expense/generate-pdf-quotation-sales",
    GeneratePDFPurchase: "/expense/generate-pdf-purchases",
    GeneratePDFPurchaseOrder: "/expense/generate-purchase-order",
    GeneratePDFExpense: "/expense/generate-pdf-expenses",
    GeneratePDFExpenseCategories: "/expense/generate-pdf-expenses/categories",
  },
  owner: {
    allOwnerCreditUrl: "/expense/business-owner-credit/",
    createOwnerCreditUrl: "/expense/business-owner-credit/",
    allOwnerDebitUrl: "/expense/business-owner-debit/",
    createOwnerDebitUrl: "/expense/business-owner-debit/",
  },
  service: {
    allServicesUrl: "/service/service/",
    createServiceUrl: "/service/service/",
    createSellServiceUrl: "/service/service-sell/",
    allSellServicesUrl: "/service/service-sell/",
  },
  supplier: {
    allSuppliers: "/expense/supplier/",
    allPurchase: "/expense/purchase/",
    allPayment: "/expense/supplier-payment/",
    SupplierIndexPDF: "/expense/supplier-index-pdf",
    supplierPaymentIndexPDF: "/expense/supplier-payment-index-pdf",
  },
  advanceSell: {
    allAdvanceSell: "/expense/advance-sell/",
  },
  purchase: {
    purchaseOrder: "/expense/purchase-order/",
  },
  quickSell: {
    allQuickSell: "/expense/cash-sell/",
  },
  shop: {
    shopInfo: "/shop_settings/shop-info",
    shopSetting: "/shop_settings/shop-setting-info/",
  },
  shopTypes: {
    shopTypesUrl: "/shop_types/shop_types/",
  },
  bank: {
    bankAccountUrl: "/shop_settings/bank-accounts/",
  },
  accounting: {
    root: "/book_keeping/",
    chartOfAccounts: "/book_keeping/chart_of_accounts/",
    trialBalance: "/book_keeping/trial-balance",
    accountBalances: "/book_keeping/account-balances/",
  },
  payment: {
    paymentMethodUrl: "/shop_settings/payment-method/",
    paymentMethod: "/shop_settings/payment-method/",
    bankAccounts: "/shop_settings/bank-accounts/",
    setBankUrl: "/shop_settings/payment-method/set_bank/",
    getBankUrl: "/get_banks/",
    removeBankUrl: "/remove_bank/",
  },
  language: {
    languageUrl: "/language/language/",
  },
  currency: {
    currencyUrl: "/currency/currency/",
  },
  reports: {
    saleReportUrl: "/expense/sales-report/?query_month=",
    dailyReportUrl: "/expense/daily-expense/?query_date=",
    monthlyReportUrl: "/expense/monthly-expense/?query_month=",
    dailyReportResultUrl: "/expense/result-sales-report/?query_date=",
    monthlyReportResultUrl: "/expense/result-month-sales-report/?query_month=",
    customerSales: "/reports/customer-summary/",
    customerSalesReportPDF: "/reports/customer-summary-report-pdf",
    salesReport: "/reports/sales-report/",
    salesReportIndexPDF: "/reports/sales-report-pdf",
    salesProducts: "/reports/sales-products/",
    salesByProductsReportPDF: "/reports/sales-products-pdf",
    InventoryMovementPDF: "/reports/inventory-movement-pdf",
    purchaseReport: "/reports/purchase-report/",
    purchaseReportPDF: "/reports/purchase-report-pdf",
    expenseReport: "/reports/expense-report/",
    expenseReportPDF: "/reports/expense-report-pdf",
    supplierPerformanceReport: "/reports/supplier-performance-report/",
    supplierPerformanceReportPDF: "/reports/supplier-performance-report-pdf",
    supplierPaymentReport: "/reports/supplier-payment-report/",
    supplierPaymentReportPDF: "/reports/supplier-payment-report-pdf",
    incomeReport: "/reports/income-report/",
    incomeReportPDF: "/reports/income-report-pdf",
    jurnalReportPDF: "/reports/jurnal-report-pdf",

    warehouseTransferReportPDF: "/reports/warehouse/transfer-pdf",
  },
  quotation: {
    salesQuotation: "/expense/sales-quotation/",
  },

  subscription: {
    me: "/subscriptions/me/",
    catalogs: "/subscriptions/catalogs/",
    packages: "/subscriptions/subscriptions/",
    currentPackage: "/subscriptions/subscribe/",
    buyPackage: "/subscriptions/subscribe/",
    checkoutPackage: "/subscriptions/checkout/",
  },
  cmsBlog: {
    allBlog: "/cms/blog/",
    sitemapDataBlog: "/cms/sitemap_blog/",
    sitemapDataFeature: "/cms/sitemap_features/",
    featuredBlog: "/cms/featured_blogs/",
    recentBlog: "/cms/recent_blogs/",
  },
  landingPage: {
    aboutTopPageData: "/cms/get/about-top-item/",
    aboutItems: "/cms/get/about-item/",
    chooseUs: "/cms/get/choose-us-item/",
    testimonial: "/cms/get/testimonial/",
    PageData: "/cms/get/page-list/",
    HomePageFeatures: "/cms/get/home-page-features/",
  },
  appIntegration: {
    appIntegration: "/api/app-integration/",
    appSubscription: "/api/app-subscription/",
  },

  appTotodeal: {
    items: "/api/service/item/",
    category: "/api/service/category/",
    offers: "/api/service/offer/",

    BRANCH: "/api/branch/",
    BRANCH_QRCODE: "/api/branch-qrcode/",
    BRANCH_CATEGORIES: "/api/categories/",
    BRANCH_CATEGORIES_FORM_DATA: "/api/categories/form_data/",
    BRANCH_ITEM: "/api/branch-item/",

    CATEGORIES_ITEMS: "/api/item/",
    BRANCH_ADMIN: "/api/branch-admin/list/",
  },

  branches: {
    branch: "/api/branch",
    branchWarehouse: "/api/branches/warehouse",
  },
};
