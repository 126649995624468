import Cookies from "universal-cookie";
import { TOKEN_STORE_KEY } from "../../constants/storage";

const cookies = new Cookies();
const OPTIONS = { path: "/", sameSite: true };

export const storeToken = (token) => {
  cookies.set(TOKEN_STORE_KEY, token, OPTIONS);
};

export const retrieveToken = () => {
  try {
    const token = cookies.get(TOKEN_STORE_KEY);
    if (token) {
      return token;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const removeToken = () => {
  cookies.remove(TOKEN_STORE_KEY, OPTIONS);
};

export const parseToken = (req) => {
  const _cookies = new Cookies(req.headers.cookie);
  const token = _cookies.get(TOKEN_STORE_KEY);
  if (token) {
    return token;
  }
  return null;
};

export const refreshToken = () => {};
