import Cookies from "universal-cookie";
import {USER_STORE_KEY} from '../../constants/storage'

const cookies = new Cookies();

const OPTIONS = { path: "/", sameSite: true };

export const storeUser = (user) => {
  cookies.set(USER_STORE_KEY, user, OPTIONS);
};

export const retrieveUser = () => {
  try {
    const user = cookies.get(USER_STORE_KEY);
    if (user) {
      return user;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const parseUser = (req) => {
  const _cookies = new Cookies(req.headers.cookie);
  const user = _cookies.get(USER_STORE_KEY);
  if (user) {
    return user;
  }
  return null;
};

export const removeUser = () => {
    cookies.remove(USER_STORE_KEY, OPTIONS);
}

