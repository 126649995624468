import React from "react";
import { classPrefix } from "../../../config/config";
import { connect } from "react-redux";
import Header from "../header/Header";
import dynamic from "next/dynamic";
import Script from "next/script";

const Footer = dynamic(() => import("../footer/Footer"), {
  loading: () => {
    return "";
  },
  ssr: false,
});

const DefaultLayout = ({ children }) => {
  return (
    <div id={`__${classPrefix}__`}>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-MV340LSGT6"
      />
      <Script
        onLoad={() => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());

          gtag("config", "G-MV340LSGT6");
        }}
      />
      <Script
        onLoad={() => {
          var Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date();
          (function () {
            var s1 = document.createElement("script"),
              s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/6628c23da0c6737bd12fa6de/1hs7jdgok";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
          })();
        }}
      />
      <Script
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/3ba3b396a80d7ad0f9a7d2f6/script.js"
      />
      <Header />
      <div id="__layout__">
        <main className="main">{children}</main>
      </div>
      <Footer />
    </div>
  );
};
export default connect(null, null)(DefaultLayout);
